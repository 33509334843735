<template>
	<div>
		<searchCard :reset="reset">
			<div class="flex">
				<div class="w-1/5 mr-4">
					<h6>연결/해제</h6>
					<vSelect v-model="mappingOpts.kind" :clearable="false" :options="optionsKey" />
				</div>
				<div class="w-1/5 mr-4">
					<h6>핫트 아이디</h6>
					<b-form-input
						v-model.trim="mappingOpts.hottId"
						name="keyword"
						placeholder="핫트 아이디"
						@keyup.enter="securityNumMap"
					/>
				</div>
				<div class="w-1/5 mr-4">
					<h6>전화번호</h6>
					<b-form-input
						v-model.trim="mappingOpts.rn"
						name="keyword"
						placeholder="전화번호"
						@keyup.enter="securityNumMap"
					/>
				</div>
				<div class="w-2/5">
					<h6 class="w-1/1">딜번호</h6>
					<b-form-input
						class="w-1/2 inline-block"
						v-model.trim="mappingOpts.dealNum"
						name="keyword"
						placeholder="딜번호"
						@keyup.enter="securityNumMap"
					/>
					<b-button class="px-12 ml-4 -top-0.5" @click="securityNumMap">적용</b-button>
				</div>
			</div>
		</searchCard>
	</div>
</template>

<script>
const optionsKey = [
		{ label: '연결', value: '1' },
		{ label: '해제', value: '2' },
	],
	defaultMappingOpts = {
		kind: optionsKey[0],
		hottId: '',
		rn: '',
		dealNum: '',
	}

export default {
	data() {
		return {
			optionsKey,
			mappingOpts: { ...defaultMappingOpts },
		}
	},
	methods: {
		reset() {
			this.mappingOpts = { ...defaultMappingOpts }
		},
		securityNumMap() {
			let error = []
			const opt = this.mappingOpts
			if (!opt.kind) error.push('매핑 타입을 선택해주세요')
			if (!opt.hottId) error.push('핫트아이디를 입력해주세요')
			if (!opt.rn) error.push('전화번호를 입력해주세요')
			if (!opt.dealNum) error.push('딜번호를 입력해주세요')

			if (error.length) alert.w(error)
			else {
				putApi('/securityphoneHandle/updateManually', {
					tkGbn: getDropdownValue(opt.kind),
					hottId: opt.hottId,
					rn: opt.rn,
					dealNum: opt.dealNum,
				}).then(() => {
					alert.s('정보 수정되었습니다')
				})
			}
		},
	},
}
</script>
